.features {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;
  padding: 0 2.5svw;
}

.feature {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

h2 {
  margin: 0;
  font-size: 1.4rem;
}

.stepIcon {
  max-height: 2rem;
  object-fit: contain;
  max-width: 2.5rem;
}

.stepOne {
  max-width: 2rem;
}

.whiteText {
  color: #ffffff !important;
}

p {
  margin: -0.3rem 0 0 0;
  color: #b2b2b2;
  font-family: "Source Sans 3";
}

@media screen and (max-width: 768px) {
  .features {
    flex-direction: column;
    padding: 0 5svw;
  }
}
