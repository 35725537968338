:root {
  --brand-color: #6eda6b;
  --black: #151515;
}

/* Apply box-sizing globally */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", "Source Sans 3", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  color: white;
  background: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  /* padding: 0.3rem; */
  font-size: 1.15rem;
  font-weight: bold;
  box-shadow: none;
  outline: none;
  background: var(--brand-color);
  color: var(--black);
  border-color: var(--brand-color);
  border-radius: 0.5rem;
  border: 1px solid var(--brand-color);
  padding: 0.5rem 1.5rem;
  transition: background 200ms ease, color 200ms ease;
  cursor: pointer;
  border-radius: 50px;
}

.login {
  /* padding: 0.3rem; */
  font-size: 1.15rem;
  font-weight: bold;
  box-shadow: none;
  outline: none;
  background: var(--brand-color);
  color: var(--black);
  border-color: var(--brand-color);
  border-radius: 0.5rem;
  border: 1px solid var(--brand-color);
  padding: 0.5rem 1.5rem;
  transition: background 200ms ease, color 200ms ease;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
}

.loginLinkHoverColor {
  color: var(--black);
  transition: color 200ms ease;
}

.loginLinkHoverColor:hover {
  color: var(--brand-color);
}

a {
  text-decoration: none;
  color: inheret;
}

.login:hover {
  background: none;
  color: var(--brand-color);
}

button:hover {
  background: none;
  color: var(--brand-color);
}

.sidePadding {
  padding: 0 2.5svw;
}

h1 {
  font-size: 3rem;
  margin: 0;
  max-width: 24ch;
}

.heroWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
}

.mainWrapper {
  height: 100svh;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerWrapper {
  padding: 0rem 2.5svw 1rem 2.5svw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.9rem;
  align-items: center;
}

.lowerWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}
.bottomSeparator {
  width: 95%;
  margin: 0.5rem auto 0 auto;
  height: 1px;
  background: #444444;
}

.footerPages {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  color: white;
}

.footerPage {
  text-decoration: none;
  color: white !important;
  transition: opacity 200ms ease;
}

.footerPage {
  text-decoration: none;
  color: var(--black);
  transition: opacity 200ms ease;
}

.footerPage:hover {
  opacity: 0.7;
}

.copyright {
  color: lightgray;
}

.copyrightLight {
  color: var(--black);
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.emailInput {
  font-size: 1.15rem;
  border-radius: 50px;
  color: white;
  border: 1px solid #444444;
  background: none;
  min-width: 300px;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
}

.emailInput::placeholder {
  color: white;
}

.socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.social {
  height: 44px;
  display: flex;
  align-items: center;
  transition: opacity 200ms ease;
}

.social:hover {
  opacity: 0.7;
}

.socialIcon {
  max-width: 32px;
}

[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}
[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}
[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}
[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}
[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}
[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}
[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
    padding: 0 5svw;
  }
  .footerWrapper {
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 2rem;
  }
  .bottomSeparator {
    margin-top: 2rem;
  }
}

.joinBetaHeadline {
  font-size: 2rem;
  margin-top: 2rem;
  color: var(--brand-color);
  font-weight: bold;
}

.sib-form-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sib-captcha {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
