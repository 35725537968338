.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1.3rem 2.5svw;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
}

/* .sidePadding {
  padding: 0 2.5svw;
} */

.menuWrapper {
}

.login {
  /* padding: 0.3rem; */
  font-size: 1.15rem;
  font-weight: bold;
  box-shadow: none;
  outline: none;
  background: var(--brand-color);
  color: var(--black);
  border-color: var(--brand-color);
  border-radius: 0.5rem;
  border: 1px solid var(--brand-color);
  padding: 0.5rem 1.5rem;
  transition: background 200ms ease, color 200ms ease;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
}

.loginLinkHoverColor {
  color: var(--black);
  transition: color 200ms ease;
}

.loginLinkHoverColor:hover {
  color: var(--brand-color);
}

a {
  text-decoration: none;
  color: var(--black);
  transition: color 200ms ease;
}

a:hover {
  color: var(--brand-color);
}

.login:hover {
  background: none;
  color: var(--brand-color);
}

.allNav {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.navButtons {
  font-size: 1.3rem;
  font-weight: bold;
}

.navButton {
  font-size: 2rem;
}

.menuItem {
}

.navLogo {
  max-height: 2rem;
  margin: 0 auto;
}
